<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <!-- <div>分会干事</div> -->
    </div>
    <div class="thetitle">2023年度报告</div>

    <div class="profile">
      <div class="steps">
        <div
          v-for="(item, index) in 9"
          :key="index"
          :class="dynamic[index] && 'click'"
          @click="next(index + 1)"
        >
          {{ item }}
        </div>
      </div>
      <!-- 第一步 -->
      <div v-if="step == 1">
        <div class="title"><div class="infor">第1步-会员信息</div></div>
        <div class="row">
          <div class="nation" style="margin-bottom: 20px">
            <div class="titler">分会会员总数：</div>
            <div class="input">
              <el-input
                type="number"
                v-model="lastname.first_page_member_total"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="cityer">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input
                v-model="lastname.first_page_currency"
                placeholder=""
              ></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="nation">
            <div class="titler">分会职业会员费：</div>
            <div class="input">
              <el-input
                v-model="lastname.first_page_professional_membership_fee"
                placeholder=""
                type="number"
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">分会学生会员费：</div>
            <div class="input">
              <el-input
                v-model="lastname.first_page_student_membership_fee"
                placeholder=""
                type="number"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="nation">
            <div class="titler">IADGE分会干事数：</div>
            <div class="input">
              <el-input
                v-model="lastname.first_page_secretary_number"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">下次选举日期：</div>
            <div class="input">
              <a-date-picker
                size="large"
                @change="getdate"
                :locale="i18nLocale"
                v-model:value="lastname.first_page_date_next_election"
                placeholder="请选择日期"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="hint input">
            <div class="nation">
              <div class="titler">&nbsp;</div>
              <div class="hintsize">
                <!-- <div>
                注：您可以在现任干事的任期届满后3个月内添加未来干事的任期。请注意任职日期不能重叠！
              </div>
              <div>单击下面的日历图标以确定任职日期：</div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="input">
            <div
              class="save hoverbottom"
              :class="{ click: submit }"
              @click="next(2)"
            >
              已保存, 下一步
            </div>
          </div>
        </div>
      </div>
      <!-- 第二步 -->
      <div v-if="step == 2">
        <div class="title"><div class="infor">第2步-银行信息</div></div>
        <div class="row">
          <div class="nation">
            <div class="titler">用户人名称：</div>
            <div class="input">
              <el-input
                v-model="lastname.second_page_account_holder_name"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">账号：</div>
            <div class="input">
              <el-input
                v-model="lastname.second_page_account_number"
                placeholder=""
              ></el-input>
              <!-- <div class="hintsize">(所有财务数据均使用此货币)</div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="nation">
            <div class="titler">开户行：</div>
            <div class="input">
              <el-input
                v-model="lastname.second_page_bank_deposit"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">联系电话：</div>
            <div class="input">
              <el-input
                v-model="lastname.second_page_mobile"
                placeholder=""
                type="number"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="nation">
            <div class="titler">身份证号：</div>
            <div class="input">
              <el-input
                v-model="lastname.second_page_idcard"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">单位名称：</div>
            <div class="input">
              <el-input
                v-model="lastname.second_page_department"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="titler">SWIFT(如有请填写)：</div>
          <div class="input">
            <el-input
              v-model="lastname.second_page_swift"
              placeholder=""
            ></el-input>
          </div>
        </div>

        <div class="row">
          <div class="titler">&nbsp;</div>
          <div class="input">
            <div class="last hoverbottom" @click="next(1)">上一步</div>
            <div
              class="save hoverbottom"
              :class="{ click: submit1 }"
              @click="next(3)"
            >
              已保存,下一步
            </div>
          </div>
        </div>

        <!-- <div  @click="next(2)">已保存, 下一步</div> -->
      </div>
      <!-- 第三步 -->
      <div v-if="step == 3">
        <div class="title">
          <div class="infor">第3步-现金收支(2022年7月1日至2023年6月30日)</div>
        </div>
        <div class="hintr violet">
          <div>
            注：1、如本年度没有任何现金收支，请在右边勾选O，可直接进入下一步；
          </div>
          <div>
            2、如下面某一项没有数据，请您输入数字“0”以进行填充，否则无法进入下一步。
          </div>
        </div>
        <div class="Continue">
          <a-checkbox
            v-model:checked="lastname.third_page_no_cash_flow"
            @change="handupdate()"
          />
          <div class="money">本年度没有任何现金收支</div>
        </div>
        <div class="row">
          <!-- <div class="nation">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input v-model="lastname.email" placeholder=""></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div> -->

          <div class="cityer">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input
                v-model="lastname.third_page_currency"
                placeholder=""
              ></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div>

          <div class="cityer">
            <div class="titler">初始余额：</div>
            <div class="input hint">
              <el-input
                v-model="lastname.third_page_initial_balance"
                placeholder=""
              ></el-input>
              <div class="hintsize">
                (截至2022年7月1日的所有银行账户和手头现金总额)
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="nation">
            <div class="titler">从IADGE总部及其他分会收到的款项：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_money_received"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">联系电话：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_mobile"
                placeholder=""
                type="number"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">其他费用:</div>
          <div class="input hint">
            <el-input
              v-model="lastname.third_page_other_expenses"
              placeholder=""
            ></el-input>
            <div class="hintsize">
              (1.会议资金；2.研讨会为所有分会会议收取的总额，包括会议的午餐和晚餐费用；3.研讨会及座谈会等报名费)
            </div>
          </div>
        </div>

        <div class="row">
          <div class="nation">
            <div class="titler">应付款：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_account_payable"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">从发表的论著中获得的款项等：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_published_works_account_payable"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="nation">
            <div class="titler">筹款：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_raise_money"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">利息收入：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_Interest_income"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="nation">
            <div class="titler">其他以上未计入的收入：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_unaccounted_revenue"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">现金收入总额：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_total_cash_receipts"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="nation" style="margin-bottom: 20px">
            <div class="titler">现金支出总额：</div>
            <div class="input">
              <el-input
                v-model="lastname.third_page_total_cash_outlay"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="cityer">
            <div class="titler">期末结余：</div>
            <div class="input hint">
              <el-input
                v-model="lastname.third_page_closing_balance"
                placeholder=""
              ></el-input>
              <div class="hintsize">
                (截至2023年6月30日的所有银行账户和手头现金总额)
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">&nbsp;</div>
          <div class="input">
            <div class="last hoverbottom" @click="next(2)">上一步</div>
            <div
              class="save hoverbottom"
              :class="{ click: submit2 }"
              @click="next(4)"
            >
              已保存,下一步
            </div>
          </div>
        </div>

        <!-- <div  @click="next(2)">已保存, 下一步</div> -->
      </div>
      <!-- 第五步 -->
      <div v-if="step == 5">
        <div class="title">
          <div class="infor">第5步-向工会捐款</div>
        </div>
        <div class="hintr violet">
          <div>注：如本年没有捐款,请在右边勾选O，可直接进入下一步；</div>
        </div>
        <div class="Continue">
          <a-checkbox
            v-model:checked="lastname.fifth_page_no_chapter_donation"
            @change="handupdate()"
          />
          <div class="money">没有向工会捐款</div>
        </div>
        <div class="row">
          <!-- <div class="nation">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input v-model="lastname.email" placeholder=""></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div> -->

          <div class="city">
            <div class="titler">单位/个人名称：</div>
            <div class="input">
              <el-input
                v-model="lastname.fifth_page_department"
                placeholder=""
              ></el-input>
              <!-- <div class="hintsize">(所有财务数据均使用此货币)</div> -->
            </div>
          </div>

          <div class="city">
            <div class="titler">时间：</div>
            <div class="input">
              <a-date-picker
                size="large"
                @change="getdate2"
                :locale="i18nLocale"
                v-model:value="lastname.fifth_page_time"
                placeholder="请选择日期"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">活动:</div>
          <div class="input hint">
            <el-input
              v-model="lastname.fifth_page_event"
              placeholder=""
            ></el-input>
          </div>
        </div>

        <div class="row">
          <div class="titler">地点:</div>
          <div class="input hint">
            <el-input
              v-model="lastname.fifth_page_location"
              placeholder=""
            ></el-input>
          </div>
        </div>

        <div class="row">
          <div class="city">
            <div class="titler">捐款金额：</div>
            <div class="input phone">
              <el-input
                v-model="lastname.fifth_page_amount_donation"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">&nbsp;</div>
          <div class="input">
            <div class="last hoverbottom" @click="next(4)">上一步</div>
            <div
              class="save hoverbottom"
              :class="{ click: submit4 }"
              @click="next(6)"
            >
              已保存,下一步
            </div>
          </div>
        </div>

        <!-- <div  @click="next(2)">已保存, 下一步</div> -->
      </div>
      <!-- 第四步 -->
      <div v-if="step == 4">
        <div class="title">
          <div class="infor">第4步-向第三方支付的款项</div>
        </div>
        <div class="hintr violet">
          <div>
            注：1、如本年度没有任何款项，请在右边勾选O，可直接进入下一步；
          </div>
          <div>
            2、如下面某一项没有数据，请您输入数字“0”以进行填充，否则无法进入下一步。
          </div>
        </div>
        <div class="Continue">
          <a-checkbox
            v-model:checked="
              lastname.fourthly_page_no_payments_made_third_parties
            "
            @change="handupdate()"
          />
          <div class="money">没有向第三方支付的款项</div>
        </div>
        <div class="row">
          <!-- <div class="nation">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input v-model="lastname.email" placeholder=""></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div> -->

          <div class="city">
            <div class="titler">第三方单位名称：</div>
            <div class="input">
              <el-input
                v-model="lastname.fourthly_page_third_party_department"
                placeholder=""
              ></el-input>
              <!-- <div class="hintsize">(所有财务数据均使用此货币)</div> -->
            </div>
          </div>

          <div class="city">
            <div class="titler">款项金额：</div>
            <div class="input">
              <el-input
                v-model="lastname.fourthly_page_amount_money"
                placeholder=""
              ></el-input>
              <!-- <div class="hintsize">
                (截至2022年7月1日的所有银行账户和手头现金总额)
              </div> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">款项缘由:</div>
          <div class="input hint">
            <el-input
              v-model="lastname.fourthly_page_reason_payment"
              placeholder=""
            ></el-input>
            <!-- <div class="hintsize">
              (1.会议资金；2.研讨会为所有分会会议收取的总额，包括会议的午餐和晚餐费用；3.研讨会及座谈会等报名费)
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="nation">
            <div class="titler">支付时间：</div>
            <div class="input">
              <a-date-picker
                size="large"
                @change="getdate1"
                :locale="i18nLocale"
                v-model:value="lastname.fourthly_page_payment_time"
                placeholder="请选择日期"
              />
            </div>
          </div>
          <div class="city">
            <div class="titler">捐款收入：</div>
            <div class="input">
              <el-input
                v-model="lastname.fourthly_page_donation_income"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">&nbsp;</div>
          <div class="input">
            <div class="last hoverbottom" @click="next(3)">上一步</div>
            <div
              class="save hoverbottom"
              :class="{ click: submit3 }"
              @click="next(5)"
            >
              已保存,下一步
            </div>
          </div>
        </div>
      </div>
      <!-- 第六步 -->
      <div v-if="step == 6">
        <div class="title">
          <div class="infor">第6步-本年度活动</div>
        </div>
        <div class="hintr violet" style="margin-bottom: 60px">
          <div>注：请至少添加3次活动，否则无法进入下一步.</div>
        </div>
        <div class="row">
          <div class="city">
            <div class="titler">本年度活动次数：</div>
            <div class="input">
              <el-input
                v-model="lastname.sixth_activitie_number"
                placeholder=""
              ></el-input>
            </div>
          </div>
          <div class="city">
            <div class="titler">活动平均出席人数：</div>
            <div class="input">
              <el-input
                v-model="lastname.sixth_average_attendance"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </div>
        <div class="activitys">
          <div v-for="(item, index) in activitys" :key="index" class="activity">
            <div class="index">
              <span v-if="index < 9">0</span>{{ index + 1 }}
            </div>
            <div class="solid"></div>
            <div class="titler">
              <div class="the_title">{{ item.title }}</div>
              <div class="the_enter">
                <div class="one">
                  <div>出席人数:</div>
                  <div>{{ item.attendance }}</div>
                </div>
                <div class="one">
                  <div>普通门票费用:</div>
                  <div>{{ item.admission_fee }}</div>
                </div>
                <div class="one">
                  <div>分会开销:</div>
                  <div>{{ item.chapter_expenditure }}</div>
                </div>
                <div class="one">
                  <div>活动简要概括:</div>
                  <div>{{ item.short_overview }}</div>
                </div>
                <div class="one">
                  <div>活动开始时间:</div>

                  <div>
                    {{ item.start_time?.substring(0, 4) }}年{{
                      item.start_time?.substring(5, 7)
                    }}月{{ item.start_time?.substring(8, 10) }}日
                  </div>
                </div>
                <div class="one">
                  <div>活动结束时间:</div>

                  <div>
                    {{ item.end_time?.substring(0, 4) }}年{{
                      item.end_time?.substring(5, 7)
                    }}月{{ item.end_time?.substring(8, 10) }}日
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                line-height: 22px;
                color: #c10a74;
                cursor: pointer;
                flex: 1;
                text-align: end;
              "
              @click="
                topath('/addactivity', item.id, item.created_at.slice(0, 4))
              "
            >
              查看/编辑
              <img
                src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92.svg"
                alt=""
                style="width: 20px; height: 20px"
              />
            </div>
          </div>
        </div>

        <div class="row" style="margin-bottom: 0px; margin-top: 24px">
          <!-- <div class="titler">&nbsp;</div> -->
          <div class="input">
            <div class="addactity" @click="topath('/addactivity')">
              +添加活动
            </div>
            <!-- <div class="save" @click="next(6)">已保存,下一步</div> -->
          </div>
        </div>

        <div class="row">
          <!-- <div class="titler">&nbsp;</div> -->
          <div class="input">
            <div class="last hoverbottom" @click="next(5)">上一步</div>
            <div
              class="save hoverbottom"
              :class="{ click: submit5 }"
              @click="next(7)"
            >
              已保存,下一步
            </div>
          </div>
        </div>
      </div>
      <!-- 第7步 -->
      <div v-if="step == 7">
        <div class="title">
          <div class="infor">第7步-即将展开的活动</div>
        </div>
        <div class="hintr violet">
          <div>
            注：如还没有即将开展的活动，请在右边勾选O，可直接进入下一步；
          </div>
        </div>
        <div class="Continue">
          <a-checkbox
            v-model:checked="lastname.seventh_page_activitie_not_carried_out"
            @change="handupdate()"
          />
          <div class="money">没有即将开始的活动</div>
        </div>
        <div class="row">
          <!-- <div class="nation">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input v-model="lastname.email" placeholder=""></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div> -->

          <div class="city">
            <div class="titler">活动名称：</div>
            <div class="input">
              <el-input
                v-model="lastname.seventh_page_activitie_title"
                placeholder=""
              ></el-input>
              <!-- <div class="hintsize">(所有财务数据均使用此货币)</div> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">活动描述:</div>
          <div class="input hint">
            <el-input
              v-model="lastname.seventh_page_activitie_describe"
              type="textarea"
            />
            <!-- <div class="hintsize">
              (1.会议资金；2.研讨会为所有分会会议收取的总额，包括会议的午餐和晚餐费用；3.研讨会及座谈会等报名费)
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="nation">
            <div class="titler">活动开始时间：</div>
            <div class="input">
              <a-date-picker
                size="large"
                v-model:value="lastname.seventh_page_activitie_start_time"
                @change="getdate3"
                :locale="i18nLocale"
                placeholder="请选择日期"
              />
            </div>
          </div>
          <div class="city">
            <div class="titler">活动结束日期：</div>
            <div class="input">
              <a-date-picker
                size="large"
                v-model:value="lastname.seventh_page_activitie_end_time"
                @change="getdate4"
                :locale="i18nLocale"
                placeholder="请选择日期"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">&nbsp;</div>
          <div class="input">
            <div class="last hoverbottom" @click="next(6)">上一步</div>
            <div
              class="save hoverbottom"
              :class="{ click: submit6 }"
              @click="next(8)"
            >
              已保存,下一步
            </div>
          </div>
        </div>
      </div>
      <!-- 第8步 -->
      <div v-if="step == 8">
        <div class="title">
          <div class="infor">第8步-授权和签名</div>
        </div>
        <div class="row">
          <!-- <div class="nation">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input v-model="lastname.email" placeholder=""></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div> -->

          <div class="city start">
            <div class="titler top20">本年度合作的单位：</div>
            <div class="input" style="margin-top: 20px">
              <el-input
                v-model="lastname.eighth_page_cooperating_organization"
                type="textarea"
              />
              <!-- <el-input v-model="lastname.position" placeholder=""></el-input> -->
              <!-- <div class="hintsize">(所有财务数据均使用此货币)</div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="nation">
            <div class="titler">货币：</div>
            <div class="input hint">
              <el-input v-model="lastname.email" placeholder=""></el-input>
              <div class="hintsize">(所有财务数据均使用此货币)</div>
            </div>
          </div> -->

          <div class="city">
            <div class="titler">评论和备注：</div>
            <div class="input" style="margin-top: 20px">
              请在下方框内输入“本人特此证明，本人已对上述信息进行了审查，据本人所知，本人相信上述信息在所述财政年度是真实、正确和完整的，并且是本着诚信原则提供的。”
              <!-- <el-input v-model="lastname.position" placeholder=""></el-input> -->
              <!-- <div class="hintsize">(所有财务数据均使用此货币)</div> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">&nbsp;</div>
          <div class="input hint">
            <el-input
              v-model="lastname.eighth_page_remark"
              type="textarea"
              placeholder="请输入“本人特此证明，本人已对上述信息进行了审查，据本人所知，本人相信上述信息在所述财政年度是真实、正确和完整的，并且是本着诚信原则提供的。”"
            />
            <!-- <div class="hintsize">
              (1.会议资金；2.研讨会为所有分会会议收取的总额，包括会议的午餐和晚餐费用；3.研讨会及座谈会等报名费)
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="city">
            <div class="titler">提交人及职位：</div>
            <div class="input phone">
              <el-input
                v-model="lastname.eighth_page_name_position"
                placeholder="例：某某某-副主任"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="titler">&nbsp;</div>
          <div class="input">
            <div class="last hoverbottom" @click="next(7)">上一步</div>
            <div
              class="save hoverbottom"
              :class="{ click: submit7 }"
              @click="next(9)"
            >
              已保存,下一步
            </div>
          </div>
        </div>
      </div>

      <!-- 第9步 -->
      <div v-if="step == 9">
        <div class="title">
          <div class="infor">第9步-完成报告</div>
        </div>

        <div class="complete_report">
          <div class="toimage">
            <img
              src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E6%AD%A3%E7%A1%AE.svg"
              alt=""
            />
          </div>
          <div>《2023年度报告》已经全部完成！</div>
          <div>如需打印，请在“分会管理”页面进行打印。</div>
          <div class="save hoverbottom click" @click="next(10)">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>
        
<style lang="scss">
.el-textarea__inner {
  height: 152px;
  border: 2px solid #777777 !important;
}
.ant-picker-large {
  width: 100%;
  height: 64px;
  border: 2px solid #777777 !important;
  padding: 20px 20px !important;
  box-sizing: border-box;
  .anticon svg {
    width: 22px !important;
    height: 22px !important;
  }
}
.el-input__wrapper {
  height: 64px;
  box-sizing: border-box;
  padding: 20px 20px !important;
  border: 2px solid #777777 !important;
  border-radius: 8px !important;
}
</style>
    <script>
import { report_write, activity_year, report_detail } from "../api/twice";
import dayjs from "dayjs";
import { ElNotification } from "element-plus";
// import jsPDF from "jspdf";
export default {
  name: "Home",
  data() {
    return {
      continueadd: false,
      dynamic: [1],
      step: 1,
      i18nLocale: "zh-cn",
      activitys: [
        // {
        //   activityname: "青岛影视设计师",
        //   attendance: "5000",
        //   ticketscost: "0",
        //   expenses: "0",
        //   overview: "青岛影视设计师",
        //   start: "2023年4月13号",
        //   end: "2023年4月23号",
        // },
        // {
        //   activityname: "长三角数字媒体教育论坛暨中国杯3D数字游戏艺术大赛",
        //   attendance: "5000",
        //   ticketscost: "0",
        //   expenses: "0",
        //   overview: "长三角数字媒体教育论坛暨中国杯3D数字游戏艺术大赛",
        //   start: "2023年4月13号",
        //   end: "2023年4月23号",
        // },
        // {
        //   activityname: "SIGGRAPH亚洲分会研讨会在澳大利亚布里斯班BCEC举行",
        //   attendance: "5000",
        //   ticketscost: "0",
        //   expenses: "0",
        //   overview: "SIGGRAPH亚洲分会研讨会在澳大利亚布里斯班BCEC举行",
        //   start: "2023年4月13号",
        //   end: "2023年4月23号",
        // },
      ],
      lastname: {
        name: "",
        // 第一步
        first_page_member_total: "",
        first_page_currency: "",
        first_page_professional_membership_fee: "",
        first_page_student_membership_fee: "",
        first_page_secretary_number: "",
        first_page_date_next_election: "",
        // 第一页表单提交状态
        first_page_status: 0,
        // 第二步
        second_page_account_holder_name: "",
        second_page_account_number: "",
        second_page_bank_deposit: "",
        second_page_mobile: "",
        second_page_idcard: "",
        second_page_department: "",
        second_page_swift: "",
        // 第二页表单提交状态
        second_page_status: 0,
        // 第三步
        third_page_no_cash_flow: false,
        third_page_currency: "",
        third_page_initial_balance: "",
        third_page_money_received: "",
        third_page_mobile: "",
        third_page_other_expenses: "",
        third_page_account_payable: "",
        third_page_published_works_account_payable: "",
        third_page_raise_money: "",
        third_page_Interest_income: "",
        third_page_unaccounted_revenue: "",
        third_page_total_cash_receipts: "",
        third_page_total_cash_outlay: "",
        third_page_closing_balance: "",
        // 第三页表单保存状态
        third_page_status: 0,
        // 第四步
        fourthly_page_no_payments_made_third_parties: false,
        fourthly_page_third_party_department: "",
        fourthly_page_amount_money: "",
        fourthly_page_reason_payment: "",
        fourthly_page_payment_time: "",
        fourthly_page_donation_income: "",
        // 第四步表单保存状态
        fourthly_page_status: 0,
        // 第五步
        fifth_page_no_chapter_donation: false,
        fifth_page_department: "",
        fifth_page_time: "",
        fifth_page_event: "",
        fifth_page_location: "",
        fifth_page_amount_donation: "",
        // 第五步表单保存状态
        fifth_page_status: 0,
        // 第六步
        sixth_activitie_number: "",
        sixth_average_attendance: "",
        // 第六步表单保存状态
        sixth_page_status: 0,
        //第七步
        seventh_page_activitie_not_carried_out: false,
        seventh_page_activitie_title: "",
        seventh_page_activitie_describe: "",
        seventh_page_activitie_start_time: "",
        seventh_page_activitie_end_time: "",
        // 第七步表单保存状态
        seventh_page_status: 0,
        // 第八步
        eighth_page_remark: "",
        eighth_page_name_position: "",
        eighth_page_cooperating_organization: "",
        // 第八步表单保存状态
        eighth_page_status: 0,
        // 第九步表单保存状态
        ninth_page_status: 0,
        email: "",
        position: "",
        street: "",
        Other_address: "",
        nation: "",
        city: "",
        membership: "",
        officers: "",
        date: "",
      },
    };
  },

  methods: {
    getdate(v, model) {
      this.lastname.first_page_date_next_election = dayjs(model);
      console.log(v, model);
    },
    getdate1(v, model) {
      this.lastname.fourthly_page_payment_time = dayjs(model);
      console.log(v, model);
    },
    getdate2(v, model) {
      this.lastname.fifth_page_time = dayjs(model);
      console.log(v, model);
    },
    getdate3(v, model) {
      this.lastname.seventh_page_activitie_start_time = dayjs(model);
      console.log(v, model);
    },
    getdate4(v, model) {
      this.lastname.seventh_page_activitie_end_time = dayjs(model);
      console.log(v, model);
    },
    async getreport() {
      const data = await report_detail({
        year: this.$route.query.year,
      });
      (this.lastname.first_page_member_total = data.first_page_member_total),
        (this.lastname.first_page_currency = data.first_page_currency),
        (this.lastname.first_page_professional_membership_fee =
          data.first_page_professional_membership_fee),
        (this.lastname.first_page_student_membership_fee =
          data.first_page_student_membership_fee),
        (this.lastname.first_page_secretary_number =
          data.first_page_secretary_number),
        (this.lastname.first_page_date_next_election =
          (data.first_page_date_next_election &&
            dayjs(data.first_page_date_next_election)) ||
          ""),
        (this.lastname.first_page_status = data.first_page_status),
        (this.lastname.second_page_account_holder_name =
          data.second_page_account_holder_name),
        (this.lastname.second_page_account_number =
          data.second_page_account_number),
        (this.lastname.second_page_bank_deposit =
          data.second_page_bank_deposit),
        (this.lastname.second_page_mobile = data.second_page_mobile),
        (this.lastname.second_page_idcard = data.second_page_idcard),
        (this.lastname.second_page_department = data.second_page_department),
        (this.lastname.second_page_swift = data.second_page_swift),
        (this.lastname.second_page_status = data.second_page_status),
        (this.lastname.third_page_no_cash_flow = data.third_page_no_cash_flow),
        (this.lastname.third_page_currency = data.third_page_currency),
        (this.lastname.third_page_initial_balance =
          data.third_page_initial_balance),
        (this.lastname.third_page_money_received =
          data.third_page_money_received),
        (this.lastname.third_page_mobile = data.third_page_mobile),
        (this.lastname.third_page_other_expenses =
          data.third_page_other_expenses),
        (this.lastname.third_page_account_payable =
          data.third_page_account_payable),
        (this.lastname.third_page_published_works_account_payable =
          data.third_page_published_works_account_payable),
        (this.lastname.third_page_raise_money = data.third_page_raise_money),
        (this.lastname.third_page_Interest_income =
          data.third_page_Interest_income),
        (this.lastname.third_page_unaccounted_revenue =
          data.third_page_unaccounted_revenue),
        (this.lastname.third_page_total_cash_receipts =
          data.third_page_total_cash_receipts),
        (this.lastname.third_page_total_cash_outlay =
          data.third_page_total_cash_outlay),
        (this.lastname.third_page_closing_balance =
          data.third_page_closing_balance),
        (this.lastname.third_page_status = data.third_page_status),
        (this.lastname.fourthly_page_no_payments_made_third_parties =
          data.fourthly_page_no_payments_made_third_parties),
        (this.lastname.fourthly_page_third_party_department =
          data.fourthly_page_third_party_department),
        (this.lastname.fourthly_page_amount_money =
          data.fourthly_page_amount_money),
        (this.lastname.fourthly_page_reason_payment =
          data.fourthly_page_reason_payment),
        (this.lastname.fourthly_page_payment_time =
          (data.fourthly_page_payment_time &&
            dayjs(data.fourthly_page_payment_time)) ||
          ""),
        (this.lastname.fourthly_page_donation_income =
          data.fourthly_page_donation_income),
        (this.lastname.fourthly_page_status = data.fourthly_page_status),
        (this.lastname.fifth_page_no_chapter_donation =
          data.fifth_page_no_chapter_donation),
        (this.lastname.fifth_page_department = data.fifth_page_department),
        (this.lastname.fifth_page_time =
          (data.fifth_page_time && dayjs(data.fifth_page_time)) || ""),
        (this.lastname.fifth_page_event = data.fifth_page_event),
        (this.lastname.fifth_page_location = data.fifth_page_location),
        (this.lastname.fifth_page_amount_donation =
          data.fifth_page_amount_donation),
        (this.lastname.fifth_page_status = data.fifth_page_status),
        (this.lastname.sixth_activitie_number = data.sixth_activitie_number),
        (this.lastname.sixth_average_attendance =
          data.sixth_average_attendance),
        (this.lastname.sixth_page_status = data.sixth_page_status),
        (this.lastname.seventh_page_activitie_not_carried_out =
          data.seventh_page_activitie_not_carried_out),
        (this.lastname.seventh_page_activitie_title =
          data.seventh_page_activitie_title),
        (this.lastname.seventh_page_activitie_describe =
          data.seventh_page_activitie_describe),
        (this.lastname.seventh_page_activitie_start_time =
          (data.seventh_page_activitie_start_time &&
            dayjs(data.seventh_page_activitie_start_time)) ||
          ""),
        (this.lastname.seventh_page_activitie_end_time =
          (data.seventh_page_activitie_end_time &&
            dayjs(data.seventh_page_activitie_end_time)) ||
          ""),
        (this.lastname.seventh_page_status = data.seventh_page_status),
        (this.lastname.eighth_page_cooperating_organization =
          data.eighth_page_cooperating_organization),
        (this.lastname.eighth_page_remark = data.eighth_page_remark),
        (this.lastname.eighth_page_name_position =
          data.eighth_page_name_position),
        (this.lastname.eighth_page_status = data.eighth_page_status),
        (this.lastname.ninth_page_status = data.ninth_page_status),
        console.log(data, 9087);
      if (data.first_page_status == 1) {
        this.dynamic = [];
        this.step = 1;
        for (let i = 0; i < 1; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.second_page_status == 1) {
        this.dynamic = [];
        this.step = 2;
        for (let i = 0; i < 2; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.third_page_status == 1) {
        this.dynamic = [];
        this.step = 3;
        for (let i = 0; i < 3; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.fourthly_page_status == 1) {
        this.dynamic = [];
        this.step = 4;
        for (let i = 0; i < 4; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.fifth_page_status == 1) {
        this.dynamic = [];
        this.step = 5;
        for (let i = 0; i < 5; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.sixth_page_status == 1) {
        this.dynamic = [];
        this.step = 6;
        for (let i = 0; i < 6; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.seventh_page_status == 1) {
        this.dynamic = [];
        this.step = 7;
        for (let i = 0; i < 7; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.eighth_page_status == 1) {
        this.dynamic = [];
        this.step = 8;
        for (let i = 0; i < 8; i++) {
          this.dynamic[i] = true;
        }
      }
      if (data.ninth_page_status == 1) {
        this.dynamic = [];
        this.step = 9;
        for (let i = 0; i < 9; i++) {
          this.dynamic[i] = true;
        }
      }
    },
    async writereport() {
      const data = await report_write(this.$route.query.year, {
        first_page_member_total: this.lastname.first_page_member_total,
        first_page_currency: this.lastname.first_page_currency,
        first_page_professional_membership_fee:
          this.lastname.first_page_professional_membership_fee,
        first_page_student_membership_fee:
          this.lastname.first_page_student_membership_fee,
        first_page_secretary_number: this.lastname.first_page_secretary_number,
        first_page_date_next_election:
          this.lastname.first_page_date_next_election,
        first_page_status: this.lastname.first_page_status,
        second_page_account_holder_name:
          this.lastname.second_page_account_holder_name,
        second_page_account_number: this.lastname.second_page_account_number,
        second_page_bank_deposit: this.lastname.second_page_bank_deposit,
        second_page_mobile: this.lastname.second_page_mobile,
        second_page_idcard: this.lastname.second_page_idcard,
        second_page_department: this.lastname.second_page_department,
        second_page_swift: this.lastname.second_page_swift,
        second_page_status: this.lastname.second_page_status,
        third_page_no_cash_flow: this.lastname.third_page_no_cash_flow,
        third_page_currency: this.lastname.third_page_currency,
        third_page_initial_balance: this.lastname.third_page_initial_balance,
        third_page_money_received: this.lastname.third_page_money_received,
        third_page_mobile: this.lastname.third_page_mobile,
        third_page_other_expenses: this.lastname.third_page_other_expenses,
        third_page_account_payable: this.lastname.third_page_account_payable,
        third_page_published_works_account_payable:
          this.lastname.third_page_published_works_account_payable,
        third_page_raise_money: this.lastname.third_page_raise_money,
        third_page_Interest_income: this.lastname.third_page_Interest_income,
        third_page_unaccounted_revenue:
          this.lastname.third_page_unaccounted_revenue,
        third_page_total_cash_receipts:
          this.lastname.third_page_total_cash_receipts,
        third_page_total_cash_outlay:
          this.lastname.third_page_total_cash_outlay,
        third_page_closing_balance: this.lastname.third_page_closing_balance,
        third_page_status: this.lastname.third_page_status,
        fourthly_page_no_payments_made_third_parties:
          this.lastname.fourthly_page_no_payments_made_third_parties,
        fourthly_page_third_party_department:
          this.lastname.fourthly_page_third_party_department,
        fourthly_page_amount_money: this.lastname.fourthly_page_amount_money,
        fourthly_page_reason_payment:
          this.lastname.fourthly_page_reason_payment,
        fourthly_page_payment_time: this.lastname.fourthly_page_payment_time,
        fourthly_page_donation_income:
          this.lastname.fourthly_page_donation_income,
        fourthly_page_status: this.lastname.fourthly_page_status,
        fifth_page_no_chapter_donation:
          this.lastname.fifth_page_no_chapter_donation,
        fifth_page_department: this.lastname.fifth_page_department,
        fifth_page_time: this.lastname.fifth_page_time,
        fifth_page_event: this.lastname.fifth_page_event,
        fifth_page_location: this.lastname.fifth_page_location,
        fifth_page_amount_donation: this.lastname.fifth_page_amount_donation,
        fifth_page_status: this.lastname.fifth_page_status,
        sixth_activitie_number: this.lastname.sixth_activitie_number,
        sixth_average_attendance: this.lastname.sixth_average_attendance,
        sixth_page_status: this.lastname.sixth_page_status,
        seventh_page_activitie_not_carried_out:
          this.lastname.seventh_page_activitie_not_carried_out,
        seventh_page_activitie_title:
          this.lastname.seventh_page_activitie_title,
        seventh_page_activitie_describe:
          this.lastname.seventh_page_activitie_describe,
        seventh_page_activitie_start_time:
          this.lastname.seventh_page_activitie_start_time,
        seventh_page_activitie_end_time:
          this.lastname.seventh_page_activitie_end_time,
        seventh_page_status: this.lastname.seventh_page_status,
        eighth_page_cooperating_organization:
          this.lastname.eighth_page_cooperating_organization,
        eighth_page_remark: this.lastname.eighth_page_remark,
        eighth_page_name_position: this.lastname.eighth_page_name_position,
        eighth_page_status: this.lastname.eighth_page_status,
        ninth_page_status: this.lastname.ninth_page_status,
      });
      console.log(data);
    },
    topath(e, id, data) {
      // console.log(e, 12345);
      if (id) {
        this.$router.push({ path: e, query: { id: id, year: data } });
      } else {
        this.$router.push(e);
      }
    },
    handupdate() {},
    next(e) {
      if (e == 10) {
        this.lastname.ninth_page_status = true;
        // console.log(1);
        // this.writereport();
        // const pdf = new jsPDF();
        // pdf.setFont('宋体');
        // pdf.text(10,10,'204  fhdjkfhkdjf')
        // pdf.save('generated.pdf');
        ElNotification({
          title: "Success",
          message: "年度报告添加成功!",
          type: "success",
        });
        setTimeout(() => {
          this.$router.push("/index");
        }, 300);
      }
      if (e == 9) {
        if (!this.submit7) {
          return;
        } else {
          this.lastname.eighth_page_status = 1;
          this.writereport();
        }
      }
      if (e == 8) {
        if (!this.submit6) {
          return;
        } else {
          this.lastname.seventh_page_status = 1;
          this.writereport();
        }
      }
      if (e == 7) {
        if (!this.submit5) {
          return;
        } else {
          this.lastname.sixth_page_status = 1;
          this.writereport();
        }
      }
      if (e == 6) {
        if (!this.submit4) {
          return;
        } else {
          this.lastname.fifth_page_status = 1;
          this.writereport();
        }
      }
      if (e == 5) {
        if (!this.submit3) {
          return;
        } else {
          this.lastname.fourthly_page_status = 1;
          this.writereport();
        }
      }
      if (e == 4) {
        if (!this.submit2) {
          return;
        } else {
          this.lastname.third_page_status = 1;
          this.writereport();
        }
      }
      if (e == 3) {
        if (!this.submit1) {
          return;
        } else {
          this.lastname.second_page_status = 1;
          this.writereport();
        }
      }
      if (e == 2) {
        if (!this.submit) {
          return;
        } else {
          this.lastname.first_page_status = 1;
          this.writereport();
        }
      }
      // if (e == 9 && !this.submit7) {
      //   return;
      // } else {
      //   this.lastname.eighth_page_status = 1;
      //   this.writereport();
      // }
      // if (e == 8 && !this.submit6) {
      //   return;
      // } else {
      //   this.lastname.seventh_page_status = 1;
      //   this.writereport();
      // }
      // if (e == 7 && !this.submit5) {
      //   return;
      // } else {
      //   this.lastname.sixth_page_status = 1;
      //   this.writereport();
      // }
      // if (e == 2 && !this.submit) {
      //   return;
      // } else {
      //   this.lastname.first_page_status = 1;
      //   this.writereport();
      // }
      // if (e == 3 && !this.submit1) {
      //   return;
      // } else {
      //   this.lastname.second_page_status = 1;
      //   this.writereport();
      // }
      // if (e == 4 && !this.submit2) {
      //   return;
      // } else {
      //   this.lastname.third_page_status = 1;
      //   this.writereport();
      // }
      // if (e == 5 && !this.submit3) {
      //   return;
      // } else {
      //   this.lastname.fourthly_page_status = 1;
      //   this.writereport();
      // }
      // if (e == 6 && !this.submit4) {
      //   return;
      // } else {
      //   this.lastname.fifth_page_status = 1;
      //   this.writereport();
      // }

      // if (!this.submit) return;
      this.dynamic = [];
      this.step = e;
      for (let i = 0; i < e; i++) {
        this.dynamic[i] = true;
      }
    },
    async getactivity_year() {
      let date = new Date();
      const data = await activity_year(date.getFullYear(), {});
      console.log(data, 898989889);
      this.activitys = data;
    },
  },
  mounted() {
    this.getactivity_year();
    this.getreport();
    // console.log(this.$route.query.year, 99999);
  },
  computed: {
    submit() {
      if (
        this.lastname.first_page_member_total &&
        this.lastname.first_page_currency &&
        this.lastname.first_page_professional_membership_fee &&
        this.lastname.first_page_student_membership_fee &&
        this.lastname.first_page_secretary_number &&
        this.lastname.first_page_date_next_election
      ) {
        return true;
      } else {
        return false;
      }
    },
    submit1() {
      if (
        this.lastname.second_page_account_holder_name &&
        this.lastname.second_page_account_number &&
        this.lastname.second_page_bank_deposit &&
        this.lastname.second_page_mobile &&
        this.lastname.second_page_idcard &&
        this.lastname.second_page_department &&
        this.lastname.second_page_swift
      ) {
        return true;
      } else {
        return false;
      }
    },
    submit2() {
      if (
        !this.lastname.third_page_no_cash_flow &&
        this.lastname.third_page_currency &&
        this.lastname.third_page_initial_balance &&
        this.lastname.third_page_money_received &&
        this.lastname.third_page_mobile &&
        this.lastname.third_page_other_expenses &&
        this.lastname.third_page_published_works_account_payable &&
        this.lastname.third_page_raise_money &&
        this.lastname.third_page_Interest_income &&
        this.lastname.third_page_unaccounted_revenue &&
        this.lastname.third_page_total_cash_receipts &&
        this.lastname.third_page_total_cash_outlay &&
        this.lastname.third_page_closing_balance
      ) {
        return true;
      } else if (this.lastname.third_page_no_cash_flow) {
        return true;
      } else {
        return false;
      }
    },
    submit3() {
      if (
        !this.lastname.fourthly_page_no_payments_made_third_parties &&
        this.lastname.fourthly_page_third_party_department &&
        this.lastname.fourthly_page_amount_money &&
        this.lastname.fourthly_page_reason_payment &&
        this.lastname.fourthly_page_payment_time &&
        this.lastname.fourthly_page_donation_income
      ) {
        return true;
      } else if (this.lastname.fourthly_page_no_payments_made_third_parties) {
        return true;
      } else {
        return false;
      }
    },
    submit4() {
      if (
        !this.lastname.fifth_page_no_chapter_donation &&
        this.lastname.fifth_page_department &&
        this.lastname.fifth_page_time &&
        this.lastname.fifth_page_event &&
        this.lastname.fifth_page_location &&
        this.lastname.fifth_page_amount_donation
      ) {
        return true;
      } else if (this.lastname.fifth_page_no_chapter_donation) {
        return true;
      } else {
        return false;
      }
    },
    submit5() {
      if (
        this.lastname.sixth_activitie_number &&
        this.lastname.sixth_average_attendance &&
        this.activitys.length >= 3
      ) {
        return true;
      } else {
        return false;
      }
    },
    submit6() {
      if (
        !this.lastname.seventh_page_activitie_not_carried_out &&
        this.lastname.seventh_page_activitie_title &&
        this.lastname.seventh_page_activitie_describe &&
        this.lastname.seventh_page_activitie_start_time &&
        this.lastname.seventh_page_activitie_end_time
      ) {
        return true;
      } else if (this.lastname.seventh_page_activitie_not_carried_out) {
        return true;
      } else {
        return false;
      }
    },
    submit7() {
      if (
        this.lastname.eighth_page_cooperating_organization &&
        this.lastname.eighth_page_name_position &&
        this.lastname.eighth_page_remark &&
        this.lastname.eighth_page_remark ==
          "本人特此证明，本人已对上述信息进行了审查，据本人所知，本人相信上述信息在所述财政年度是真实、正确和完整的，并且是本着诚信原则提供的。"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>
        <style scoped lang="scss">
.click {
  background: #d15243 !important;
  font-weight: bold !important;
  color: #ffffff !important;
  &:hover {
    opacity: 0.6 !important;
  }
}
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .profile {
    padding: 56px 60px;
    // border: 2px solid #333333;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    position: relative;
    .steps {
      display: flex;
      justify-content: space-between;
      color: #000000;

      margin: -24px 0 80px 0;
      &::before {
        content: "";
        position: absolute;
        top: 58px;
        width: 100%;
        width: 1144px;
        height: 8px;
        background: #f5ddda;
        // opacity: 0.2;
      }
      div {
        z-index: 2;
        width: 60px;
        height: 60px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 1;
        border: 4px solid #d15243;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: bold;
        color: #d15243;
        line-height: 33px;
        cursor: pointer;
      }
    }
    .last {
      width: 278px;
      height: 58px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 2px solid #c81677;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #c81677;
      margin-top: 60px;
      cursor: pointer;
      text-align: center;
    }
    .save {
      width: 278px;
      //   margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #ccc;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;
      cursor: no-drop;
    }

    .Continue {
      display: flex;
      align-items: self-end;
      margin-bottom: 34px;
      justify-content: flex-end;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      align-items: center;
      .money {
        margin-left: 20px;
      }
    }

    .hintr {
      color: #d95347;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      margin-top: -8px;
      margin-bottom: 20px;
      :nth-child(2) {
        margin-left: 36px;
        margin-top: 4px;
      }
    }

    .activitys {
      display: flex;
      flex-direction: column;
      .activity {
        background: #ffffff;
        box-shadow: 0px 8px 24px 0px rgba(25, 83, 116, 0.2);
        border-radius: 20px 20px 20px 20px;
        margin-bottom: 36px;
        padding: 32px 60px;
        display: flex;
        align-items: center;
        margin-top: 20px;
        .solid {
          height: 293px;
          width: 2px;
          background: #d95347;
          margin-right: 60px;
        }
        .titler {
          height: 100%;
          display: flex;
          flex-direction: column;

          .the_title {
            font-size: 22px;
            font-weight: bold;
            color: #333333;
            line-height: 26px;
            margin-bottom: 20px;
          }
          .the_enter {
            .one {
              display: flex;
              &:nth-child(n) {
                margin-bottom: 24px;
              }
              &:last-child {
                margin-bottom: 0px;
              }
              :nth-child(1) {
                font-size: 18px;
                font-weight: bold;
                color: #333333;
                line-height: 21px;
                width: 126px;
                text-align: right;
              }
              :nth-child(2) {
                margin-left: 24px;
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
              }
            }
          }
        }
        .index {
          font-size: 60px;
          font-weight: bold;
          color: #333333;
          line-height: 70px;
          margin-right: 60px;
          //   &::after{
          //     content: "";
          //     display: block;
          //     height: 100%;
          //     width: 2px;
          //     background: #D95347;
          //     top: 0px;
          //   }
        }
      }
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      .start {
        align-items: flex-start !important;
      }

      .hint {
        flex-direction: column;
      }
      .nation {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .cityer {
        display: flex;
        align-items: center;
        flex: 1;
        position: relative;
        margin-bottom: 20px;
        .hintsize {
          position: absolute;
          bottom: -20px;
        }
      }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 178px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }

      .cont {
        display: flex;

        .Continue {
          align-items: center;
        }
      }
      .phone {
        justify-content: initial !important;
        .el-input {
          width: 40.5%;
        }
      }
      .input {
        margin-left: 20px;
        flex: 1;
        position: relative;
        display: flex;
        justify-content: space-around;
        .addactity {
          font-size: 28px;
          font-weight: bold;
          color: #000000;
          line-height: 33px;
          padding-bottom: 5px;
          cursor: pointer;
          &:hover {
            border-bottom: 4px solid #000;
          }
        }
        .hintsize {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-top: 4px;
          //   }
        }
        // }
      }
    }
    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .complete_report {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 28px;
      :nth-child(2) {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
        margin-top: 40px;
      }
      :nth-child(3) {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        margin: 16px auto 0px;
      }
      .toimage {
        width: 100px;
        img {
          width: 100%;
        }
      }
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
<style lang="scss">
.Continue {
  .ant-checkbox-inner {
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% !important;
    border: 3px solid #c81677 !important;
    &::after {
      width: 10px !important;
      height: 16px !important;
      border: 4px solid #ffffff;
      border-left: 0;
      border-top: 0;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c10a74 !important;
  }
}
</style>
        